:root {
  --padding-side: 24px;;
}

a {
  text-decoration: none;
}

p {
  margin: 0;
}

.scroll-area {
  height: 100vh;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
}

.scroll-section {
  height: 100vh;
  scroll-snap-align: start;
  scroll-snap-stop: always;
}

.fa-lg {
  font-size: 4.25em;
  cursor: pointer;
}
.fa-lg:hover {
  font-size: 4.50em;
}